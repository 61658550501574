/* BUTTON MIXIN
============================================= */
.searching button,
.filter--from,
.filter--to {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.user__img,
.object__img,
.object__img--inside,
.object__img--parking,
.building__img {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.title__big {
  font-size: 24px;
  font-weight: 300;
  color: var(--link);
}

.title__big a {
  border: 2px solid transparent;
}

.title__big a.active {
  color: var(--primary);
  border-bottom-color: var(--primary);
}

@media only screen and (max-width: 767px) {
  .title__big {
    font-size: 20px;
  }
}

.title__middle {
  font-size: 18px;
  font-weight: 300;
  color: var(--link);
  text-transform: uppercase;
}

.title__default {
  font-size: 16px;
  font-weight: 700;
  color: var(--link);
  line-height: 1;
}

.bg-blue-lighter {
  background-color: #ebf1fa !important;
}

.color-blue-darker {
  color: var(--primary) !important;
}

.f-s-18 {
  font-size: 18px !important;
}

.f-s-16 {
  font-size: 16px !important;
}

.f-s-14 {
  font-size: 14px !important;
}

.searching button:hover {
  color: var(--primary);
}

.user__img {
  width: 45px;
  height: 45px;
}

.user__name {
  font-size: 14px;
  font-weight: 300;
  color: white;
}

.user__permission {
  font-size: 13px;
  font-weight: 300;
  color: white;
}

.object {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

@media only screen and (max-width: 1247px) {
  .object {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

.object__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  border-radius: 10px;
  background-color: var(--background-color-nav);
  -webkit-box-shadow: 0px 10px 20px 5px rgba(5, 8, 9, 0.05);
  box-shadow: 0px 10px 20px 5px rgba(5, 8, 9, 0.05);
  width: 270px;
  height: 270px;
  padding: 20px;
  margin: 10px 20px 10px 0;
}

@media only screen and (max-width: 1247px) {
  .object__item {
    margin: 10px 0;
    width: 250px;
    height: 250px;
  }
}

@media only screen and (max-width: 1023px) {
  .object__item {
    width: 45%;
    height: 250px;
  }
}

@media only screen and (max-width: 767px) {
  .object__item {
    height: 220px;
  }
}

@media only screen and (max-width: 559px) {
  .object__item {
    margin: 10px auto;
    width: 100%;
    height: 300px;
  }
}

.object__item--inside {
  width: 190px;
  height: 190px;
}

@media only screen and (max-width: 1247px) {
  .object__item--inside {
    margin: 10px 0;
    width: 180px;
    height: 180px;
  }
}

@media only screen and (max-width: 1023px) {
  .object__item--inside {
    width: 48%;
    height: 220px;
  }
}

@media only screen and (max-width: 767px) {
  .object__item--inside {
    height: 165px;
  }
}

@media only screen and (max-width: 559px) {
  .object__item--inside {
    margin: 10px auto;
    width: 100%;
    height: 280px;
  }
}

.object__item--manager {
  width: 270px;
  min-height: 350px;
  height: 100%;
}

@media only screen and (max-width: 1247px) {
  .object__item--manager {
    margin: 10px 0;
  }
}

@media only screen and (max-width: 1023px) {
  .object__item--manager {
    width: 48%;
    height: 220px;
  }
}

@media only screen and (max-width: 767px) {
  .object__item--manager {
    height: 165px;
  }
}

@media only screen and (max-width: 559px) {
  .object__item--manager {
    margin: 10px auto;
    width: 100%;
    height: 280px;
  }
}

.object__img {
  width: 220px;
  height: 180px;
}

@media only screen and (max-width: 1023px) {
  .object__img {
    width: 180px;
    height: 180px;
  }
}

@media only screen and (max-width: 559px) {
  .object__img {
    width: 250px;
    height: 250px;
  }
}

.object__img--inside {
  width: 150px;
  height: 125px;
}

@media only screen and (max-width: 1023px) {
  .object__img--inside {
    width: 180px;
    height: 180px;
  }
}

@media only screen and (max-width: 559px) {
  .object__img--inside {
    width: 250px;
    height: 250px;
  }
}

.object__img--parking {
  width: 50px;
  height: 50px;
}

@media only screen and (max-width: 1023px) {
  .object__img--parking {
    width: 80px;
    height: 80px;
  }
}

@media only screen and (max-width: 559px) {
  .object__img--parking {
    width: 80px;
    height: 80px;
  }
}

.object__name {
  font-size: 24px;
  font-weight: 700;
  color: var(--link);
  line-height: 1.2;
  text-align: center;
  margin: 10px 0;
}

@media only screen and (min-width: 768px) {
  .object__name--inside {
    font-size: 20px;
  }
}

.object__info {
  font-size: 16px;
  font-weight: 500;
  color: var(--link);
  line-height: 1.2;
  text-align: center;
}

@media only screen and (min-width: 1024px) {
  .object__info--inside {
    font-size: 12px;
  }
}

.object__more-info {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 9;
}

.object__link {
  width: 100%;
  height: 100%;
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  z-index: 8;
  border-radius: 30px;
  padding: 20px;
}

.object__add {
  margin-bottom: 10px;
}

.object__add i {
  font-size: 54px;
  color: var(--plus-icon);
}

@media only screen and (min-width: 768px) {
  .object__add--inside i {
    font-size: 40px;
  }
}

.object__address {
  font-size: 14px;
  font-weight: 500;
  color: var(--link);
  line-height: 1.5;
  text-align: center;
}

.new-object {
  background-color: var(--background-color-nav);
  border-radius: 6px;

  * {
    color: var(--link);
  }
}

.new-object__inside {
  background-color: var(--background-color-dropdown);
  border-radius: 10px;
}

.apartment {
  background-color: var(--background-color-dropdown);
  border-radius: 5px;
  padding: 1rem;
  position: relative;
}

.apartment__info span {
  font-weight: 500;
  padding-left: 5px;
}

.apartment__close {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 9;
  background-color: transparent;
  border: none;
}

/*.apartment input, .apartment select {*/
/*  background-color: unset;*/
/*  border: none;*/
/*  display: inline-block;*/
/*  max-width: 50px;*/
/*  padding-left: 5px;*/
/*  font-size: 14px;*/
/*  color: var(--link);*/
/*  font-weight: 500;*/
/*  line-height: 1.714;*/
/*}*/

/*.apartment select {*/
/*  font-weight: 700;*/
/*  max-width: unset;*/
/*  padding-left: 0;*/
/*  margin-left: -4px;*/
/*  margin-top: 2px;*/
/*  margin-bottom: 3px;*/
/*  cursor: pointer;*/
/*}*/

.apartment-last {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.building__img {
  width: 100%;
  height: 300px;
  background-position: left center;

  @include mq("tablet", max) {
    height: auto;
    max-height: 300px;
  }
}

.building__type {
  margin-top: 20px;
  font-size: 16px;
  font-weight: 700;
  color: var(--link);
  line-height: 1.625;
  text-align: left;
}

@media only screen and (max-width: 767px) {
  .building__type {
    margin-top: 10px;
  }
}

.building__info {
  font-size: 18px;
  font-weight: 700;
  color: var(--link);
  line-height: 1.778;
  text-align: left;
}

.building__info p {
  margin: 0;
  padding: 0;
}

.filter {
  position: relative;
  width: 70%;
}

.filter--from,
.filter--to {
  position: absolute;
  /* Chrome, Safari, Edge, Opera */
  /* Firefox */
}

.filter--from input,
.filter--to input {
  border: unset;
  background-color: transparent;
  padding: 0;
  width: 25px;
  font-size: 14px;
  color: var(--primary);
  font-weight: 700;
}

.filter--from input::-webkit-input-placeholder,
.filter--to input::-webkit-input-placeholder {
  color: var(--primary);
}

.filter--from input::-moz-placeholder,
.filter--to input::-moz-placeholder {
  color: var(--primary);
}

.filter--from input:-ms-input-placeholder,
.filter--to input:-ms-input-placeholder {
  color: var(--primary);
}

.filter--from input::-ms-input-placeholder,
.filter--to input::-ms-input-placeholder {
  color: var(--primary);
}

.filter--from input::placeholder,
.filter--to input::placeholder {
  color: var(--primary);
}

.filter--from input::-webkit-outer-spin-button,
.filter--from input::-webkit-inner-spin-button,
.filter--to input::-webkit-outer-spin-button,
.filter--to input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.filter--from input[type="number"],
.filter--to input[type="number"] {
  -moz-appearance: textfield;
}

.filter--from {
  left: 0;
}

.filter--to {
  right: 0;
}

.filter--to input {
  text-align: right;
}

.my-range {
  width: 85%;
  margin: 20px auto;
  -webkit-transform: translateX(-5px);
  transform: translateX(-5px);
  padding: 0;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.my-range .tt {
  position: absolute;
  top: -35px;
  -webkit-transform: translateX(-20px);
  transform: translateX(-20px);
  font-weight: 700;
  color: var(--primary);
}

.my-range-slider {
  -webkit-appearance: none;
  width: 100%;
  height: 3px;
  border-radius: 5px;
  background: var(--primary);
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.my-range-slider:hover {
  opacity: 1;
}

.my-range-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: var(--primary);
  cursor: pointer;
}

.my-range-slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: var(--primary);
  cursor: pointer;
}

.currency {
  border-radius: 3px;
  padding: 10px 15px;
  font-size: 16px;
  font-weight: 500;
}

.currency__price {
  color: #fff;
  line-height: 1;
}

.currency__changing {
  color: #2ef413;
}

.socials-for-print {
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
}
.heading-for-print,
.datas-for-print {
  display: none;
}

@media print {
  * {
    color: #343a40 !important;
  }
  .heading-for-print,
  .datas-for-print {
    display: block;
  }

  .building__info-for-print {
    display: none !important;
  }

  #app > div,
  .navbar,
  .menu-expanded,
  .menu-collapsed {
    display: none !important;
  }

  main {
    width: 100%;
    margin-left: 0;
    position: relative;
    min-height: unset;
    padding: 0;

    #printMe {
      ul li {
        a {
          text-decoration: none !important;
        }
      }
      .map {
        display: none;
      }

      .apartment {
        background: #ebf1fa !important;
      }

      .object-detail {
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%;
      }

      .footer-btns {
        display: none;
      }

      .col-lg-8 {
        border-right: unset !important;
      }

      .building__img {
        height: 400px;
        margin-bottom: 50px;
      }

      .object-calculator {
        display: none;
      }

      .table {
        .thead-dark th {
          color: #fff !important;
          background-color: #343a40 !important;
          border: 1px solid #454d55 !important;
          -webkit-print-color-adjust: exact !important;
        }
        tbody td {
          border: 1px solid rgba(0, 0, 0, 0.1) !important;
          -webkit-print-color-adjust: exact !important;
        }
      }
    }
  }
}

.table-multi-select {
  display: flex;
  align-items: center;
  input {
    width: 25px;
    height: 25px;
  }

  .custom-control {
    margin-right: 13px;
    // top: -3px;
    width: 25px;
    height: 25px;
  }

  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none;
  }

  .custom-control-label::before {
    width: 25px;
    height: 25px;
    top: 0;
  }

  .custom-control-label::after {
    width: 25px;
    height: 25px;
    top: 0;
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: var(--primary);
    background-color: var(--primary);
  }
}
.custom-table {
  min-height: 80vh;
}
.custom-table table tbody tr,
.custom-table table thead tr {
  background-color: var(--background-color-nav);
  color: var(--dark);
}

.table.b-table > thead > tr > .table-b-table-default,
.table.b-table > tbody > tr > .table-b-table-default,
.table.b-table > tfoot > tr > .table-b-table-default {
  background-color: var(--background-color-nav);
  color: var(--dark);
}

.bg-custom-white {
  background-color: var(--background-color-nav);
  color: var(--link);
}

.table {
  background-color: var(--background-color-main);
  color: var(--link);
}

.custom-table table tbody {
  tr.table-info,
  tr.table-success, tr.table-warning {
    td {
      color: var(--table-ligth);
    }
  }
}

.table.b-table > thead > tr > [aria-sort="none"],
.table.b-table > tfoot > tr > [aria-sort="none"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='black' opacity='.3' d='M51 1l25 23 24 22H1l25-22zM51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e");
}

.dark-theme {
  .table.b-table > thead > tr > [aria-sort="none"],
  .table.b-table > tfoot > tr > [aria-sort="none"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='white' opacity='.3' d='M51 1l25 23 24 22H1l25-22zM51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e");
  }

  .table.b-table > thead > tr > [aria-sort="ascending"],
  .table.b-table > tfoot > tr > [aria-sort="ascending"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='white' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='white' opacity='.3' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e");
  }

  .table.b-table > thead > tr > [aria-sort="descending"],
  .table.b-table > tfoot > tr > [aria-sort="descending"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='white' opacity='.3' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='white' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e");
  }
}

