/* Define styles for the default root window element */
:root {
  --background-color: #fff;
  --background-color-nav: #fff;
  --background-color-dropdown: #f9fbff;
  --background-color-main: transparent;
  --background-color-navbar: #5851d8;
  --white: #fff;
  --plus-icon: #5851d8;
  --primary: #5851d8;
  --success: #45a85c;
  --info: #0c5460;
  --warning: #ff9f43;
  --danger: #dc3545;
  --ligth: #bebebe;
  --table-ligth: #000;
  --dark: #535353;
  --secondary: #82868b;
  --link: #222222;
  --placeholder: #969696;
  --dropdown-icon: #55547a;
  --border-color: #22222233;
  --object-color: #000;
  --element-size: 1rem;
  --btn-green-color: #000;
  --content-border-color: 1px solid rgba(0, 0, 0, .15);
}

/* Define styles for the root window with dark - mode preference */
:root.dark-theme {
  --background-color: #10163a;
  --background-color-nav: #283046;
  --background-color-navbar: #283046;
  --background-color-dropdown: #10163a;
  --background-color-main: transparent;
  --object-color: #fff;
  --white: #3b4253;
  --plus-icon: #fff;
  --primary: #7367f0;
  --success: #45a85c;
  --info: #0c5460;
  --warning: #ff9f43;
  --danger: #dc3545;
  --ligth: #3b4253;
  --dark: #dcdcdc;
  --secondary: #82868b;
  --link: #dcdcdc;
  --placeholder: #82868b;
  --dropdown-icon: #dcdcdc;
  --border-color: #dcdcdc;
  --element-size: 1rem;
  --btn-green-color: #fff;
  --content-border-color: 1px solid #ced4da;
}

html {
  scroll-behavior: smooth !important;
}

* {
  outline: none !important;
}

*::-moz-selection {
  background-color: var(--primary);
  color: white;
}

*::selection {
  background-color: var(--primary);
  color: white;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  text-decoration: none;
  color: inherit;
}

body {
  width: 100%;
  height: 100%;
  background-image: url("../img/bg_img.png");
  background-attachment: fixed;
  overflow-x: hidden;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  font-family: "MuseoSansCyrl", Arial, Helvetica, sans-serif;
  background-color: var(--background-color);
}

main {
  margin-left: 260px;
  position: relative;
  transition: all 0.3s ease;
  backface-visibility: hidden;
  min-height: calc(100% - 3rem);
  padding: 2rem 2rem 0 2rem;
  background-color: var(--background-color-main);

  @include mq("desktop", max) {
    margin: 1rem;
    padding: 0rem 0 0 0;
  }
}

main > div.d-flex.justify-content-between > div {
  margin-top: 15px !important;
  padding-left: 15px;
}

main > div.d-flex.justify-content-between > div .title__big {
  -webkit-box-ordinal-group: 1 !important;
  -ms-flex-order: 0 !important;
  order: 0 !important;
}
