









































































































































.button__new__contract {
  margin-right: 0;
}

.active__contract__tab {
  background-color: #7367f0 !important;
}
