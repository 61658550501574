



















































































































































































































































































































































































.plan__group {
  width: auto;

  .input__group {
    border: 1px solid rgb(206, 212, 218);
    border-radius: 0.25rem;
    margin: 0 0 0.5rem 0;
    padding: 0;
  }


  &-input {
    max-width: 16rem;
    border: none;
    padding-left: 0.5rem;
    background-color: transparent;
  }
}

.select__type__promo {
  border: 1px solid rgb(206, 212, 218);
  border-radius: 0.25rem;
  margin-bottom: 0.5rem;
}

.select_wrapper {
  padding: 0.1rem;
  border-right: 1px solid rgb(206, 212, 218);
}

.delete__button {
  cursor: pointer;
  padding-bottom: 12px;
  margin-left: 0.5rem;

  i {
    color: red;
    font-size: 28px;
    transition: font-size ease-in;

    &:hover {
      font-size: 24px;
    }
  }
}

.error__provider {
  color: red;
  font-size: 14px;
}
