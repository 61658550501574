.btn-back {
  background-color: transparent;
  border: unset;
  color: white;
}

.btn-green-bg {
  background-color: #41b883;
  color: white !important;
}

a,
a:hover,
.my-btn,
.my-dropdown__two .dropdown-menu .dropdown-item,
.my-table tbody tr,
input.my-input,
.my-btn__group input,
.my-form__input,
.menu__img,
.menu__name {
  transition: all 0.3s ease-in;
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: none;
}

button:focus {
  outline: none !important;
  outline: 0 auto -webkit-focus-ring-color;
}

.btn-dark:not(:disabled):not(.disabled).active:focus,
.btn-dark:not(:disabled):not(.disabled):active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  -webkit-box-shadow: 0;
  box-shadow: 0;
}

.btn:not(.dropdown-item) {
  box-shadow: unset !important;
  border: 1px solid transparent !important;
  padding: 0.786rem 1.5rem !important;
  border-radius: 0.358rem !important;
  text-align: center;
  font-weight: 500;
  user-select: none;
  font-size: 1rem;
  line-height: 1;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, background 0s,
  border 0s, -webkit-box-shadow 0.15s ease-in-out !important;
  text-transform: none;

  display: flex !important;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  margin-right: 1rem;
  height: 45px;
  color: white;

  i {
    color: white;
  }
}

.btn i,
.btn span,
.btn svg,
.btn img {
  display: inline-block;
  margin: 0 6px;
}

.btn-sm {
  max-width: 50px;
}

.btn-primary {
  color: #fff !important;
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
}

.btn-primary:hover {
  color: #fff;
  background-color: #463ed3 !important;
  border-color: #463ed3 !important;
  box-shadow: 0 8px 25px -8px #463ed3 !important;
}

.btn-success {
  color: #fff;
  background-color: #45a85c !important;
  border-color: #45a85c !important;

  svg * {
    color: white !important;
  }
}

.btn-success:hover {
  color: #fff;
  background-color: #429c57 !important;
  border-color: #429c57 !important;
  box-shadow: 0 8px 25px -8px #429c57 !important;
}

.btn-secondary:not(.dropdown-item) {
  color: #fff;
  background-color: #82868b !important;
  border-color: #82868b !important;
}

.btn-secondary:not(.dropdown-item):hover {
  color: #fff;
  background-color: #82868b !important;
  border-color: #82868b !important;
  box-shadow: 0 8px 25px -8px #82868b !important;
}

.btn-warning {
  color: #fff !important;
  background-color: #ff9f43 !important;
  border-color: #ff9f43 !important;
}

.btn-warning:hover {
  color: #fff;
  background-color: #ff9f43 !important;
  border-color: #ff9f43 !important;
  box-shadow: 0 8px 25px -8px #ff9f43 !important;
}

.btn-warning {
  color: #fff !important;
  background-color: #138496 !important;
  border-color: #138496 !important;
}

.btn-info:hover {
  color: #fff;
  background-color: #138496 !important;
  border-color: #138496 !important;
  box-shadow: 0 8px 25px -8px #138496 !important;
}

.btn-default {
  color: #414141 !important;
  background-color: #e7e7e7 !important;
  border-color: #e7e7e7 !important;

  i {
    color: #414141 !important;
  }
}

.btn-light {
  color: #414141 !important;
  background-color: #e7e7e7 !important;
  border-color: #e7e7e7 !important;

  i {
    color: #414141 !important;
  }
}

.btn-default:hover {
  color: #414141 !important;
  background-color: #e7e7e7 !important;
  border-color: #e7e7e7 !important;
  box-shadow: 0 8px 25px -8px #e7e7e7 !important;

  i {
    color: #414141 !important;
  }
}

.btn-outline-secondary {
  border: 1px solid #82868b !important;
  background-color: transparent;
}

.btn-outline-secondary:hover:not(.disabled):not(:disabled) {
  background-color: rgba(130, 134, 139, 0.04);
}

.btn-outline-secondary:hover:not(.disabled):not(:disabled) {
  color: #82868b;
}

.b-form-datepicker {
  .btn {
    padding: 0.375rem 0.75rem !important;
  }

  .b-calendar-nav {
    .btn {
      margin: 0 !important;
    }
  }

  & > .btn {
    margin: 0 !important;
  }
}
