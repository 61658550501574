.new-object {
  border: none;
  margin-bottom: 2rem;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  background-color: var(--background-color);
  background-clip: border-box;
  border-radius: 0.428rem;
  padding: 1rem;
  @include mq("tablet", max) {
    padding-left: 0;
    padding-right: 0;
  }
}

.app-content {
  border: none;
  margin-bottom: 2rem;
  background-clip: border-box;
  border-radius: 0.428rem;
}

img[lazy="error"] {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
table.b-table[aria-busy="true"] {
  opacity: 0.6;
}

.custom-table table tbody tr td {
  font-size: 16px;
  font-weight: 300;
  color: var(--link);
}

.custom-table table tr th,
.custom-table table tr td {
  padding: 15px 25px;
  vertical-align: middle;
  border-top: unset;
}

.custom-table table tbody tr,
.custom-table table thead tr {
  background-color: var(--background-color-nav);
  border-radius: 6px;
  box-shadow: -2px -2px 7px #ffffff73, 1px 1px 5px rgba(94, 104, 121, 0.288);
}

.custom-table table thead tr th {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 25px !important;
  font-size: 14px;
  font-weight: 300;
  color: var(--link);
  border-bottom: 1px solid rgba(190, 190, 190, 0.336);
  text-transform: uppercase;
}

.custom-table tr {
  background-color: transparent;
  transition: all 0.3s ease-in;
}

.custom-table tr th:first-of-type,
.custom-table tr td:first-of-type {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.custom-table tr th:last-of-type,
.custom-table tr td:last-of-type {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.custom-table table {
  border-collapse: separate;
  border-spacing: 0 1em;
}

.custom-table table tbody tr:hover {
  background-color: transparent;
  -webkit-box-shadow: 0px 3px 9px 1px rgba(5, 8, 9, 0.08);
  box-shadow: 0px 3px 9px 1px rgba(5, 8, 9, 0.08);
}

.custom-table.b-table-sticky-header {
  max-height: 80vh;
}

.custom-table.b-table-sticky-header::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.custom-table.b-table-sticky-header::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0);
}

.custom-table.b-table-sticky-header::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0);
  border-radius: 0;
}

.custom-table.b-table-sticky-header::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0);
}

.table.b-table > thead > tr > [aria-sort].b-table-sort-icon-left,
.table.b-table > tfoot > tr > [aria-sort].b-table-sort-icon-left {
  background-position: left calc(1rem / 2) center;
  padding-left: calc(0.75rem + 0.65em);
}

.fixed-menu,
.object__link {
  box-shadow: 0 0 15px 0 rgba(34, 41, 47, 0.05);
}

select.form-control {
  height: 45px !important;
}

.dark-theme {
  .custom-table table tbody tr:hover {
    background-color: transparent;
    box-shadow: 0px 3px 9px 1px rgba(5, 8, 9, 0.08);
  }

  .custom-table table tbody tr,
  .custom-table table thead tr {
    box-shadow: -2px -2px 7px #ffffff10, 1px 1px 5px rgba(94, 104, 121, 0.658);
  }
}
