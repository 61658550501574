


























































































































































































































































































































.promo__creation__modal {
  .btn {
    margin: 0 !important;
  }
}

.promo__info__button {
  background: transparent;
  border-radius: 50% !important;
  margin: 0 0 0.5rem 0 !important;
  padding: 0;
  outline: none;
  border: none;
}

.time__select {
  display: flex;
  max-height: 40px;

  .input__date {
    max-width: 180px;
    height: 100%;
    margin-right: 1rem;
  }

  .form__timepicker {
    max-width: 150px;
    height: 100%;
  }
}

.modal-content .error__provider {
  color: red;
  font-size: 14px;
}
