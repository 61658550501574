













































































































































































.payment__content {
  //background-color: var(--primary);
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 400px));
}
