// Fonts
@font-face {
  font-family: "MuseoSansCyrl";
  src: url("../fonts/MuseoSansCyrl/MuseoSansCyrl-100.ttf") format("truetype");
  font-weight: 100;
}

@font-face {
  font-family: "MuseoSansCyrl";
  src: url("../fonts/MuseoSansCyrl/MuseoSansCyrl-300.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "MuseoSansCyrl";
  src: url("../fonts/MuseoSansCyrl/MuseoSansCyrl-500.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "MuseoSansCyrl";
  src: url("../fonts/MuseoSansCyrl/MuseoSansCyrl-700.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "MuseoSansCyrl";
  src: url("../fonts/MuseoSansCyrl/MuseoSansCyrl-900.ttf") format("truetype");
  font-weight: 900;
}
