


































































































































































































































.download__icon {
  cursor: pointer;
}

.star__icon {
  color: var(--plus-icon);
}
