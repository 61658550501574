




































































.user__account__icons {
  fill: var(--dark);
}

.user__account__icons-active {
  fill: white;
}
