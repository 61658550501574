








































































































































































































































.create__branch {
  max-width: 720px;
  margin-top: 1rem;
}

.overlay__alert {
  max-width: 640px;
}

.success__alert {
  max-width: 640px;
}

.form__password {
  max-width: 640px;

  .validation__provider {
    display: block;
    margin-top: 1rem;
  }

  .error__provider {
    color: red;
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .submit__button {
      background-color: #007bff !important;
      margin-right: 0;
    }
  }
}

.input__text__icon {
  cursor: pointer;
  height: 38px;
}

