























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.booking__button {
  background-color: #fab005 !important;
}

.svg-lock-button {
  fill: var(--dark);
}

.apartments__lock {
  position: absolute;
  left: -20px;
  top: 25%;
  transform: translateY(-30%);
}

.space-room-button {
  background: #f1f1f1;
  box-shadow: -1px 5px 22px -12px rgba(0, 0, 0, 0.75);
  border-radius: 8px;
  padding-left: 8px;
  padding-right: 8px;
  border: 0.1px solid rgba(0, 0, 0, 0.2)
}

.dropdown-menu .active {
  background: transparent;
}

.cursor-pointer {
  cursor: pointer;
}

table thead th {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: var(--background-color-nav);
}

.my-table thead tr th {
  padding-top: 20px;
  padding-bottom: 20px;
}

table tbody th {
  position: relative;
}

table tbody th {
  position: sticky;
  left: 0;
  z-index: 1;
}

.apartment-list-filter {
  @media screen and (max-width: 576px) {
    .btn {
      width: 100%;
    }
  }
}
