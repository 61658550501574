






























































































































































































































































































































































































































































































































































































































































































































































































































































.building__info .promo__marker {
  margin-left: 0.5rem;
  border-radius: 1rem;
  color: #28a745;
  background-color: rgba(#28a745, .2);
  padding: 0.25rem 2rem;
}

.loading-content {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.building__img img {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  cursor: pointer;
}

.toggle-map {
  top: 48%;
  left: -20px;
  transform: translateY(-50%);
  position: absolute;
  width: 40px;
  height: 40px;
  background-color: white;
  border: 1px solid #b8b8b8;

  @media screen and (max-width: 576px) {
    display: none;
  }
}

.ymap-container {
  height: 100%;
  width: 100%;
}

@media screen and (min-width: 576px) {
  .map-active {
    position: relative;
    right: 0;

    .map-active {
      width: 100%;
      position: absolute;
      height: 100%;
      z-index: 112;
      right: 0;

      .ymap-container {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  map {
    margin-top: 30px;
  }
  .ymap-container {
    height: 400px;
  }
}
