
































































































































































































































































































































.avatar-wrapper {
  background-color: darkorchid;
  border-radius: 50%;
  box-shadow: 0.5px 0.5px -1px 0 rgba(0, 0, 0, 0.75);
}

.branches__icon {
  fill: var(--dark);
}
