



































































input {
  display: none;
}
span {
  i {
    cursor: pointer;
    color: white;
    font-size: 24px;
  }
}
