















































































































































.prepay__input__content {
  input {
    height: 40px !important;
  }

  .percent__icon {
    height: 40px;

    i {
      color: #000000;
    }
  }

}

.prepay__addition__button .btn {
  padding: 4px;
  font-size: 12px;
  height: 40px !important;
}

