input.my-input,
.my-btn__group input,
.my-form__input {
  border-style: solid;
  border-width: 1px;
  border-color: var(--border-color);
  border-radius: 3px;
  padding: 5px 15px;
  line-height: 2;
  width: 95%;
  height: 42px;
  display: block;
  margin: 15px auto;
  color: var(--link);
  background-color: var(--background-color-nav);
}

input.my-input::-webkit-input-placeholder,
.my-btn__group input::-webkit-input-placeholder,
.my-form__input::-webkit-input-placeholder {
  color: var(--link);
}

input.my-input::-moz-placeholder,
.my-btn__group input::-moz-placeholder,
.my-form__input::-moz-placeholder {
  color: var(--link);
}

input.my-input:-ms-input-placeholder,
.my-btn__group input:-ms-input-placeholder,
.my-form__input:-ms-input-placeholder {
  color: var(--link);
}

input.my-input::-ms-input-placeholder,
.my-btn__group input::-ms-input-placeholder,
.my-form__input::-ms-input-placeholder {
  color: var(--link);
}

input.my-input::placeholder,
.my-btn__group input::placeholder,
.my-form__input::placeholder {
  color: var(--link);
}

input.my-input:focus,
.my-btn__group input:focus,
.my-form__input:focus {
  border-color: var(--border-color);
}

@media only screen and (min-width: 560px) {
  input.my-input,
  .my-btn__group input,
  .my-form__input {
    width: 400px;
  }
}

.my-form__input {
  margin: 0;
  width: 100%;
}

.my-form__textarea {
  height: 150px;
  padding-top: 12px;
  padding-bottom: 12px;
  line-height: 1.5;
  resize: none;
}

.my-form label {
  font-size: 14px;
  font-weight: 500;
  color: var(--link);
}

.my-custom-control .custom-control-label::before,
.my-custom-control .custom-control-label::after {
  top: 0.15rem;
}

.my-custom-control
  .custom-control-input:checked
  ~ .custom-control-label::before {
  color: var(--link);
  border-color: var(--primary);
  background-color: var(--primary);
}

.my-custom-control .custom-control-input:focus ~ .custom-control-label::before {
  -webkit-box-shadow: unset;
  box-shadow: unset;
}

// .form-control:focus {
//   outline: 0;
//   -webkit-box-shadow: none;
//   box-shadow: none;
// }

// .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label,
// .input-group > .custom-select:focus,
// .input-group > .form-control:focus {
//   z-index: 0;
// }

// input.form-control,
// input,
// textarea {
//   width: 100%;
//   padding: 0.438rem 1rem;
//   background-color: #fff;
//   background-clip: padding-box;
//   border: 1px solid #d8d6de;
//   border-radius: 0.357rem;
//   height: 45px;
//   font-size: 1rem;
//   font-weight: 400;
//   line-height: 1.45;
//   color: $dark;
//   transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
//     -webkit-box-shadow 0.15s ease-in-out;
// }

// input.form-control:focus,
// input:focus,
// textarea:focus,
// select:focus {
//   background-color: #fff;
//   border-color: #7367f0;
//   box-shadow: 0 3px 10px 0 rgba(34, 41, 47, 0.1);
// }
// input.form-control:focus,
// input,
// textarea,
// select {
//   color: $dark;
//   outline: 0;
// }
// input.form-control:focus.is-valid,
// input.form-control:focus:valid,
// input:focus,
// textarea:focus,
// select:focus {
//   box-shadow: 0 3px 10px 0 rgba(34, 41, 47, 0.1);
// }

// textarea {
//   height: 100px;
//   resize: none;
// }

.b-form-tags-button {
  display: none !important;
}

.b-form-tags-button {
  padding: 3px !important;
  margin: 0 !important;
  height: unset !important;
}

.form-control:focus,
.custom-select:focus {
  box-shadow: unset;
  border-color: inherit !important;
  color: var(--link);
  background-color: var(--background-color-nav);
}

.form-control.is-invalid:focus {
  border-color: #dc3545 !important;
  box-shadow: unset;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  background-image: none;
  box-shadow: unset;
  border-color: inherit;

  &:focus {
    background-image: none;
    box-shadow: unset;
    border-color: inherit;
  }
}

.was-validated .form-control:valid,
.form-control.is-valid {
  padding: 0.375rem 0.75rem;
}

.form-control {
  background-color: var(--background-color-nav);
  color: var(--link);

  &::placeholder {
    color: var(--placeholder);
  }
}

.custom-select {
  background-color: var(--background-color-nav);
}

.multiselect__tags {
  background-color: var(--background-color-nav);
}

.multiselect__content-wrapper {
  background-color: var(--background-color-nav);
}

.b-form-tags.focus {
  background-color: var(--background-color-nav);
  box-shadow: unset !important;
  border-color: #000;

  .multiselect__tags {
    background-color: var(--background-color-nav);
    box-shadow: unset !important;
  }

  .multiselect__content-wrapper {
    background-color: var(--background-color-nav);
    box-shadow: unset !important;
  }
}

.b-calendar-grid-body {
  span:not(.text-dark) {
    color: #000;
  }
}

.dark-theme {
  .b-calendar-grid-body {
    span:not(.text-dark) {
      color: #fff;
    }
  }

  .table-success,
  .table-success > th,
  .table-success > td {
    border-color: #9bd1a8cb !important;
    background-color: #9bd1a8cb !important;
  }
  .table-info,
  .table-info > th,
  .table-info > td {
    border-color: #72aeb8 !important;
    background-color: #72aeb8 !important;
  }
  .table-warning,
  .table-warning > th,
  .table-warning > td {
    border-color: #ffeeba !important;
    background-color: #ffeeba !important;
  }
  .table-danger,
  .table-danger > th,
  .table-danger > td {
    border-color: #ed969e !important;
    background-color: #ed969e !important;
  }

  .b-form-tags.focus {
    background-color: var(--background-color-nav);
    box-shadow: unset !important;
    border-color: white;

    .multiselect__tags {
      background-color: var(--background-color-nav);
      box-shadow: unset !important;
    }

    .multiselect__content-wrapper {
      background-color: var(--background-color-nav);
      box-shadow: unset !important;
    }
  }
  .b-form-datepicker .dropdown-menu {
    background-color: $dark;

    .text-dark {
      color: #a8afb8 !important;
    }
    .text-muted {
      color: #6c757d !important;
    }
    .bg-light {
      background-color: $dark !important;
    }
    .btn-outline-light:hover {
      color: #212529;
      background-color: #0000007d;
      border-color: #f8f9fa;
    }
  }
  .b-form-datepicker.focus {
    background-color: $dark;
    box-shadow: unset;
  }

  .b-form-btn-label-control.form-control[aria-disabled="true"],
  .b-form-btn-label-control.form-control[aria-readonly="true"] {
    background-color: $dark;
    opacity: 1;
  }

  .form-control:disabled,
  .form-control[readonly] {
    background-color: #888888;
  }
}
